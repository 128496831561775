import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);



  const imageUrls = [
    'https://i.ibb.co/7vDzKJf/20231014-214346140-i-OS-min.jpg',
    'https://i.ibb.co/ZJWfvmZ/20231105-194220351-i-OS-min.jpg',
    'https://i.ibb.co/W5nKqgQ/20231106-011314661-i-OS-min.jpg',
    'https://i.ibb.co/p214gKc/20231205-184841935-i-OS-min.jpg',
    'https://i.ibb.co/tM9T5PT/20240129-171154174-i-OS-min.jpg',
    'https://i.ibb.co/68JhQDM/20240129-171859989-i-OS-min.jpg',
    'https://i.ibb.co/7vDzKJf/20231014-214346140-i-OS-min.jpg',
    'https://i.ibb.co/ZJWfvmZ/20231105-194220351-i-OS-min.jpg',
    'https://i.ibb.co/W5nKqgQ/20231106-011314661-i-OS-min.jpg',
    'https://i.ibb.co/p214gKc/20231205-184841935-i-OS-min.jpg',
    'https://i.ibb.co/tM9T5PT/20240129-171154174-i-OS-min.jpg',
    'https://i.ibb.co/68JhQDM/20240129-171859989-i-OS-min.jpg'
  ];


  return (
    <div className="container" >
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

          <div className='textContainer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <p style={{ fontSize: '28px', margin: '0 0 10px 0' }}> 
            <strong>🎁80% OFF SALE🎁 👻</strong>
        </p>
        <div className="detailsText">
            🍑 Mia Stone <br/>
            ❤️ 28 years<br/>
            🟢 Online Now<br/>
            📍 {city ? `${city} ${region}` : 'Your City'}<br/><br/>

            I personally respond to messages on here   <br/><br/>

            Message me to create a real connection, let’s see where things go ❤️
        </div>
    </div>



      <a href="https://onlyfans.com/yourunikorn/c7" id="customButton" >
        Send me a message
      
      </a>

      <p className="exclusiveContent">
       <strong>Let's have fun 😉 Only $3.00 today 🔥</strong>
      </p>
      <Analytics/>
    </div>
  );
}

export default App;
